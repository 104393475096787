import React from 'react';

import Layout from '@layouts';
import { Banners, SEO, Ambassador } from '@components';

const { Ambassador: AmbassadorBanner } = Banners;

const Index = () => (
  <Layout bannerChildren={<AmbassadorBanner />} bannerStyle={{ backgroundImage: 'none' }}>
    <SEO
      title='BECOME AN AMBASSADOR FOR TAIK'
      description='Apply to become an Ambassador at The Africa I Know, Inc.'
    />

    <Ambassador />
  </Layout>
);

export default Index;
